import * as LocalStorage from './local-storage';
import * as SessionStorage from './session-storage';
export var hasItem = function hasItem(cacheType, prefix, id) {
    if (testStorage(cacheType)) {
        switch (cacheType) {
            case "LOCAL_STORAGE":
                return LocalStorage.hasItem(prefix, id);
            default:
                return SessionStorage.hasItem(prefix, id);
        }
    }
};
export var getItem = function getItem(cacheType, prefix, id) {
    if (testStorage(cacheType)) {
        switch (cacheType) {
            case "LOCAL_STORAGE":
                return LocalStorage.getItem(prefix, id);
            default:
                return SessionStorage.getItem(prefix, id);
        }
    }
};
export var setItem = function setItem(cacheType, prefix, id, value) {
    if (testStorage(cacheType)) {
        switch (cacheType) {
            case "LOCAL_STORAGE":
                LocalStorage.setItem(prefix, id, value);
                break;
            default:
                SessionStorage.setItem(prefix, id, value);
        }
    }
};
export var removeItem = function removeItem(cacheType, prefix, id) {
    if (testStorage(cacheType)) {
        switch (cacheType) {
            case "LOCAL_STORAGE":
                LocalStorage.removeItem(prefix, id);
                break;
            default:
                SessionStorage.removeItem(prefix, id);
        }
    }
};
export var buildKey = function buildKey(prefix, id) {
    return prefix + "::" + id;
};
export var testStorage = function testStorage(cacheType) {
    var storage;
    try {
        storage = cacheType == "LOCAL_STORAGE" ? window["localStorage"] : window["sessionStorage"];
        if (storage) {
            storage.setItem("test", "test");
            storage.removeItem("test");
            return true;
        }
        return false;
    }
    catch (e) {
        return false;
    }
};
