import DOMWindow from "../util/dom-wrapper/window";
import useLatestEditionSpotlight from "./use-latest-edition-spotlight";
export var useIsEditionSpotlightMagazine = function useIsEditionSpotlightMagazine() {
    var path = DOMWindow.locationPathname();
    var result = "is-edition";
    if (["", "/"].includes(path)) {
        var _useLatestEditionSpot = useLatestEditionSpotlight(), edition = _useLatestEditionSpot.edition, spotlight = _useLatestEditionSpot.spotlight;
        if (new Date(spotlight.firstPublishedAt) > new Date(edition.firstPublishedAt)) {
            result = "is-spotlight";
        }
    }
    else {
        if (DOMWindow.locationPathname().startsWith("/themenhefte")) {
            result = "is-magazine";
        }
        else if (DOMWindow.locationPathname().startsWith("/spotlight")) {
            result = "is-spotlight";
        }
        else if (DOMWindow.locationPathname().startsWith("/alle-spotlights")) {
            result = "is-spotlight";
        }
    }
    return result;
};
