export var replaceQuotationMarksWithGuillemets = function replaceQuotationMarksWithGuillemets(text) {
    /*
    let temp = text
    if (text) {
      temp = temp.replace(/"(.*?)"/g, "»$1«")
      temp = temp.replace(/'(.*?)'/g, "›$1‹")
      temp = temp.replace("„", "»")
      temp = temp.replace("“", "«")
      temp = temp.replace("”", "«")
      temp = temp.replace("‚", "›")
      temp = temp.replace("‘", "‹")
      temp = temp.replace("’", "‹")
      temp = temp.replace("&bdquo;", "»") // „
      temp = temp.replace("&ldquo;", "«") // “
      temp = temp.replace("&rdquo;", "«") // ”
      temp = temp.replace("&sbquo;", "‹") // ‚
      temp = temp.replace("&lsquo;", "‹") // ‘
      temp = temp.replace("&rsquo;", "‹") // ’
      return temp
    }
    return temp
     */
    return text;
};
