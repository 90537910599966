import { buildKey } from "./cache";
export var hasItem = function hasItem(prefix, id) {
    return localStorage.getItem(buildKey(prefix, id)) !== null;
};
export var getItem = function getItem(prefix, id) {
    return JSON.parse(localStorage.getItem(buildKey(prefix, id)));
};
export var setItem = function setItem(prefix, id, value) {
    localStorage.setItem(buildKey(prefix, id), JSON.stringify(value));
};
export var removeItem = function removeItem(prefix, id) {
    localStorage.removeItem(buildKey(prefix, id));
};
