import { buildKey } from "./cache";
export var hasItem = function hasItem(prefix, id) {
    return sessionStorage.getItem(buildKey(prefix, id)) !== null;
};
export var getItem = function getItem(prefix, id) {
    return JSON.parse(sessionStorage.getItem(buildKey(prefix, id)));
};
export var setItem = function setItem(prefix, id, value) {
    sessionStorage.setItem(buildKey(prefix, id), JSON.stringify(value));
};
export var removeItem = function removeItem(prefix, id) {
    sessionStorage.removeItem(buildKey(prefix, id));
};
