import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import { useStaticQuery } from "gatsby";
var useLatestEditionSpotlight = function useLatestEditionSpotlight() {
    var _useStaticQuery = useStaticQuery("1534434256"), allDatoCmsEdition = _useStaticQuery.allDatoCmsEdition, allDatoCmsSpotlight = _useStaticQuery.allDatoCmsSpotlight;
    return {
        edition: {
            articles: assembleEditionArticles(allDatoCmsEdition),
            firstPublishedAt: new Date(allDatoCmsEdition.nodes[0].meta.firstPublishedAt)
        },
        spotlight: {
            articles: assembleSpotlightArticles(allDatoCmsSpotlight),
            firstPublishedAt: new Date(allDatoCmsSpotlight.nodes[0].meta.firstPublishedAt)
        }
    };
};
export default useLatestEditionSpotlight;
var assembleEditionArticles = function assembleEditionArticles(data) {
    if (!data.nodes || data.nodes.length <= 0)
        return null;
    var edition = data.nodes[0];
    return edition.sections.flatMap(function (section) {
        return section.articles.map(function (article) {
            return article.id;
        });
    }).concat(edition.editorial.id);
};
var assembleSpotlightArticles = function assembleSpotlightArticles(data) {
    if (!data.nodes || data.nodes.length <= 0)
        return null;
    var spotlight = data.nodes[0];
    return spotlight.articles.map(function (article) {
        return article.id;
    }).concat(spotlight.briefing.id);
};
